<template>
  <div class="order-list-wrapper" v-loading="activeName === '2' ? addOrderLoading : false"
    :element-loading-text="'企业当前正在执行【导入订单并发货】...' + progress" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)">
    <en-table-layout :tableData="activeName === '1' ? tableData : tableDataTwo" :loading="loading"
      :span-method="orderSpanMethod" @selection-change="orderListSelectionChange">
      <template slot="toolbar">
        <el-form-item label="订单状态" class="col-auto">
          <el-select style="width: 100px;" v-model="params.order_status" size="medium">
            <template v-if="$route.name === 'comboOrderList'">
              <el-option v-for="item in MixinOrderStatusOptions[activeName === '2' ? 3 : 4]" :key="item.value"
                :label="item.label" :value="item.value" />
            </template>
            <template v-else-if="$route.name === 'shopOrderList'">
              <el-option v-for="item in MixinOrderStatusOptions[activeName === '2' ? 1 : 0]" :key="item.value"
                :label="item.label" :value="item.value" />
            </template>
            <template v-else>
              <el-option v-for="item in MixinOrderStatusOptions[0]" :key="item.value" :label="item.label"
                :value="item.value" />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" class="col-auto" v-if="activeName === '1'">
          <el-select style="width: 100px" v-model="params.trade_type" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="普通商品订单" :value="1" />
            <el-option label="礼包订单" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" class="col-auto">
          <el-input style="width: 200px" v-model.trim="advancedForm.cash_company" placeholder="请输入"
            clearable></el-input>
        </el-form-item>
        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item v-if="shop_self_goods_open" label="提货类型" class="col-auto">
          <el-select style="width: 100px" v-model="params.pick_up" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="邮寄商品订单" :value="1" />
            <el-option label="餐类商品订单" :value="2" />
            <el-option label="0元商品订单" :value="4" />
            <el-option label="自提商品订单" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键词" class="col-auto" style="margin-bottom:0">
          <el-input style="width: 280px" v-model.trim="advancedForm.keywords" placeholder="请输入关键词" clearable>
            <el-select style="width: 130px;" v-model="search_type" slot="prepend">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="买家账号" value="mobile_account"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option label="储值卡号" value="card_number"></el-option>
              <el-option label="礼包名称" value="shop_combo_name"></el-option>
              <el-option label="备注信息" value="shop_remake"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item class="col-auto" style="margin-bottom:0">
          <el-button @click="advancedSearchEvent();" size="small" type="primary">搜索</el-button>
          <!--          站点导出订单-->
          <el-button v-if="ispetroChina" @click="zsysubmitImport();" size="small" type="primary">导出订单</el-button>
          <el-button v-else @click="submitImport();" size="small" type="primary">导出订单</el-button>
        </el-form-item>
        <div class="col"></div>
      </template>
      <template slot="table-columns" v-if="activeName === '1' ? tableData[0] : tableDataTwo[0]">
        <el-table-column type="selection" />

        <el-table-column label="订单编号" prop="sn" width="180" fixed="left">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">{{ row.trade_sn }}</template>
            <template v-else>{{ row.sn }}</template>
          </template>
        </el-table-column>

        <el-table-column label="订单总额" width="100" fixed="left">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              <span>
                {{
      (row.trade_kind == 1 ? row.shop_goods_price + row.shop_freight_price : 0) | unitPrice('￥')
    }}
              </span>
            </template>
            <!-- 自有订单 -->
            <template v-else>{{ (row.total_self_owned_orders_price)
      | unitPrice('￥') }}</template>
          </template>
        </el-table-column>

        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.image || row.goods_image" style="width: 300px;" alt />
              <img slot="reference" :src="row.image || row.goods_image" class="goods-cover" alt />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" prop="goods_name" show-overflow-tooltip width="200" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </template>
        </el-table-column>


        <el-table-column label="商品单价" width="100">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              <template>
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.shop_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.revise_exchange_money | unitPrice('￥') }} +
                  {{ row.revise_exchange_point }}积分
                </div>
              </template>
            </template>

            <template v-else>
              <template>
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.enterprise_purchase_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.revise_exchange_money | unitPrice('￥') }} +
                  {{ row.revise_exchange_point }}积分
                </div>
              </template>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" prop="goods_num" width="80" />

        <el-table-column label="售后状态" width="80">
          <template slot-scope="{row}">
            {{ row.delivery_method == 1 ? MixinGetOrderStatus(row.service_status) : '未申请' }}
          </template>
        </el-table-column>

        <el-table-column label="物流状态" width="80">
          <template slot-scope="{row}">
            <span v-if="row.delivery_method !== 1">未发货</span>
            <span v-else-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
            <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="物流单号" width="300" show-overflow-tooltip>
          <template slot-scope="{row}">
            <template v-if="row.delivery_method !== 1">
              {{ row.delivery_method == 2 ? '餐类自提' : '自提' }}
            </template>
            <template v-else-if="row.delivery">{{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}</template>
          </template>
        </el-table-column>
        <el-table-column label="供货方" width="80">
          <template slot-scope="{row}">
            <span v-if="row.delivery_method !== 1">自有</span>
            <span v-else>{{ row.order_source === 0 ? '平台' : (row.order_source === 1 ? '京东' : '自有') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="订单状态" width="100">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              {{ row.trade_status_text }}
              <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
              <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
              <!--              <div v-if="row.hangup_status === 3" style="color: red;">(供应商挂起)</div>-->
              <!--              <template v-if="row.trade_status !== 'CANCELLED'">-->
              <!--                {{ row.trade_status_text }}-->
              <!--              </template>-->
              <!--              <div v-else>-->
              <!--                <div>{{ row.trade_status_text }}</div>-->
              <!--                <div v-if="row.hangup_status === 0" style="color: red;">(系统原因)</div>-->
              <!--              </div>-->
            </template>
            <template v-else>
              {{ row.order_status_text === '待发货' && row.logistics_status === 1 ? '拣货中' : row.order_status_text }}
              <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
              <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
              <!--              <div v-if="row.hangup_status === 3" style="color: red;">(供应商挂起)</div>-->
            </template>
          </template>
        </el-table-column>
        <el-table-column label="订单类型" prop="trade_type" width="110">
          <template slot-scope="{row}">
            {{ row.shop_combo_name ? '礼包订单' : '普通商品订单' }}
          </template>
        </el-table-column>
        <el-table-column label="下单时间" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>

        <el-table-column label="用户账号" prop="mobile" width="150" class-name="font-family-tttgb">
          <template slot-scope="{row}">
            <div v-if="row.mobile && row.login_account">
              <p>{{ row.mobile }}</p>
              {{ row.login_account }}
            </div>
            <div v-else>
              {{ row.mobile }}
              {{ row.login_account }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="用户备注信息" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="remark-box">
                {{ row.remark }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- 分销利润 和 商城利润 -->
        <template v-if="activeName === '2' && MixinIsFormEnterprise">
          <el-table-column label="分销利润" width="100">
            <template slot-scope="{row}">
              <template v-if="activeName === '1'">{{ row.distribution_profit | unitPrice('￥') }}</template>
              <template v-else>0</template>
            </template>
          </el-table-column>

          <el-table-column label="商城利润" width="100">
            <template slot-scope="{row}">
              <template v-if="activeName === '1'">{{ (row.revise_price - row.enterprise_total_price).toFixed(2) |
      unitPrice('￥') }}</template>
              <template v-else>{{ (row.revise_price - row.enterprise_purchase_price).toFixed(2) | unitPrice('￥')
                }}</template>
            </template>
          </el-table-column>
          <el-table-column v-if="activeName === '2'" label="备注信息" show-overflow-tooltip width="120">
            <template v-if="row.remark_update_date && row.order_remark" slot-scope="{row}">{{ row.remark_update_date |
      unixToDate }}:{{ row.order_remark.replaceAll("/n", "\n") }}</template>
          </el-table-column>
        </template>
        <el-table-column label="备注信息" show-overflow-tooltip width="120">
          <template slot-scope="{row}">{{ row.shop_remake }}</template>
        </el-table-column>
        <el-table-column label="操作" :width="activeName === '2' ? 290 : 200" fixed="right">
          <template slot-scope="{row}">
            <div style="display: flex;justify-content: center">
              <el-button size="mini" type="primary" @click="editOrderRemark(row)"> 备注</el-button>
              <el-button type="primary" size="small" @click="handleOperateOrder(row)">查看详情</el-button>
              <div v-if="row.isCancelOrder" style="margin-left: 8px">
                <el-button type="primary" size="small" @click="cancelTradeHandle(row)">取消订单</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入不超100字的备注信息" maxlength="100"
          v-model="remarkForm.remark"></el-input>
      </div>
    </x-dialog>
    <x-dialog :proxy="logisticsDialog">
      <!-- <div style="padding:10px"> -->
      <en-table-layout :table-data="sku_list">
        <template slot="table-columns">
          <el-table-column prop="goods_sn" label="商品编号" width="150"></el-table-column>
          <el-table-column prop="goods_name" label="商品名称" min-width="200">
            <template slot-scope="{row}">
              {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
            </template>
          </el-table-column>
          <el-table-column prop="ship_num" label="商品数量" width="100"></el-table-column>
          <el-table-column label="物流公司" width="200">
            <template slot-scope="scope">
              <el-select size="mini" :disabled="scope.row.state === 3" v-model="scope.row.logi_id" placeholder="请选择"
                @change="filterLogisticsData(scope.row)">
                <el-option v-for="item in logisticsData" :key="item.logi_id" :label="item.name" :value="item.logi_id" />
              </el-select>
              <!--                <input type="text" style="display: none" v-model="scope.row.logi_name"/>-->
            </template>
          </el-table-column>
          <el-table-column label="物流单号" width="260px">
            <template slot-scope="scope">
              <ul class="wlcz" style="list-style: none; padding-left: 10px; margin-bottom: 0;">
                <el-input size="mini" placeholder="请输入物流单号" v-model="scope.row.delivery_no"></el-input>
              </ul>
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
      <!-- </div> -->
    </x-dialog>
    <el-dialog :title="funcName === 'importAndShipSellerOrder' ? '批量导入并发货' : '导入历史物流信息'" :visible.sync="batchImport"
      width="85%" class="import-order-dialog" @closed="importData = []">
      <div class="buttonAll">
        <upload-excel-component :on-success="excelSuccess" :cancel-loading='true' />
      </div>
      <el-table :data="importData"
        :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }">
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column v-for="(item, index) in templateKey" :prop="item" :key="item" align="center"
          :label="templateHedaer[index]"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batchImport = false">取 消</el-button>
        <el-button type="primary" @click="submitFh">确 定</el-button>
      </div>
    </el-dialog>
    <x-dialog :proxy="orderRemarkDialog">
      <el-form :model="orderOremarkForm" ref="orderOremarkForm" label-width="80px">
        <br />
        <el-form-item label="备注信息">
          <el-input v-model="orderOremarkForm.shop_remake" type="textarea" show-word-limit :rows="4" resize="none"
            placeholder="请输入100字以内的备注信息" :maxlength="100" clearable></el-input>
        </el-form-item>
        <br />
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_order from "@/api/order";
import * as API_logistics from "@/api/expressCompany";
import { handleDownload, mergeSkuList, mergesHandleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
import { CategoryPicker } from "@/components";
import UploadExcelComponent from "@/components/UploadExcel";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { getEnterpriseISZiYou } from "@/api/order";
import { importOrderRequest, pollImportResult } from "../../../api/order";
// import * as API_Member from '@/api/member';
import * as API_Goods from '@/api/goods';
import { getSellerShopExtAuth, getCnpcShopIds } from '@/api/account'
export default {
  name: "orderList",
  components: {
    XDialog,
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
    UploadExcelComponent
  },
  computed: {
    ...mapGetters(["shopInfo"]),
    timeDefault () {
      const date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() - 1)
      );
    }
  },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      importLoading1: false,
      importLoading2: false,
      dialogVisible: false,

      // 列表选中数据
      tempList: [],

      ownOrderStatus: [],

      orderPublishDialog: $xDialog.create({
        title: "订单发货",
        width: "80vw",
        disableConfirm: true,
        disableCancel: true,
        showClose: true
      }),
      logisticsDialog: $xDialog.create({
        title: "物流信息",
        width: "80vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editLogistics
      }),
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      orderRemarkDialog: $xDialog.create({
        width: "500px",
        beforeConfirm: () => this.submitRemark(),
      }),
      // 备注信息
      remarkForm: {
        sn: "",
        platform: "",
        remark: "",
        member_name: ""
      },
      // 订单备注信息
      orderOremarkForm: {
        trade_sn: '',
        shop_remake: "",
      },
      activeName: null,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        order_status: "ALL",
        ...this.$route.query
      },
      mallOptions: [{
        value: '',
        label: '全部'
      }, {
        value: '2',
        label: '现金商城'
      }, {
        value: '3',
        label: '套餐卡商城'
      }],
      /** 列表分页数据 */
      pageData: {},
      comParams: {
        cgrade: 2,
        page_no: 1,
        page_size: 20
      },
      /** 高级搜索数据 */
      advancedForm: {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: []
      },
      batchImport: false,
      importData: [],
      isError: false,
      templateHedaer: [
        "商品名称以及规格",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号"
      ],
      templateKey: [
        "name",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ],
      funcName: "",
      /** 表格最大高度 */
      tableMaxHeight: "",
      search_type: "order_sn",
      obj: {},
      /*订单商品数据*/
      sku_list: [],
      selectList: [],
      ship_no: "",
      /** 物流信息 */
      logisticsData: [],
      formInline: {
        logi_id: ""
      },
      orderDetail: {},
      hasOwnGoods: false,
      addOrderLoading: false,
      pollTime: null,
      progress: '0%',
      tableData: [],
      tableDataTwo: [],
      shop_self_goods_open: false,//是否有自建商品开关
      ispetroChina: false,//是否是中石油商城
      merges: [],//表格导出合并
    };
  },
  async mounted () {
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    const res = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
    if (res.shop_self_goods_open == 'OPEN') {
      this.shop_self_goods_open = true
      this.params.pick_up = ''
    }
    this.params.trade_type = ''
    this.ownOrderStatus = [
      {
        value: 'ALL',
        label: '全部'
      },
      {
        value: 'WAIT_PAY',
        label: '待付款'
      },
      {
        value: 'WAIT_SHIP',
        label: '待发货'
      },
      {
        value: 'PICKING',
        label: '拣货中'
      },
      {
        value: 'WAIT_ROG',
        label: '待收货'
      },
      {
        value: 'COMPLETE',
        label: '已完成'
      },
      {
        value: 'CANCELLED',
        label: '已取消'
      },
    ]
    this.pollImportResult('init')
    if (sessionStorage.getItem('orderListActiveName') === '2') {
      this.activeName = sessionStorage.getItem('orderListActiveName')
    } else {
      this.activeName = '1'
    }
    delete this.params.market_enable;
    let { type } = this.$route.query;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
    this.params["shop_type"] = 1;

    const arr = [];

    switch (type) {
      case "1":
        this.params.order_status = "PAID_OFF";
        break;
      case "3":
        this.params.order_status = "ALL";
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date = new Date(); //获取Date对象
        now_date.setHours(0); //设置小时
        now_date.setMinutes(0); //设置分钟
        now_date.setSeconds(0); //设置秒
        now_date.setMilliseconds(0); //设置毫妙
        let timestamp = now_date.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp - 86400,
          end_time: timestamp - 1
        };
        break;
      case "4":
        this.params.order_status = "COMPLETE";
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date1 = new Date(); //获取Date对象
        now_date1.setHours(0); //设置小时
        now_date1.setMinutes(0); //设置分钟
        now_date1.setSeconds(0); //设置秒
        now_date1.setMilliseconds(0); //设置毫妙
        let timestamp1 = now_date1.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp1 - 86400,
          end_time: timestamp1 - 1
        };
        break;
      default:
        break;
    }

    this.GET_OrderList(this.activeName);
    this.getLogisticsCompanies();
  },
  beforeRouteUpdate (to, from, next) {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    this.GET_OrderList();
    next();
  },
  destroyed () {
    sessionStorage.removeItem('orderListActiveName')
  },
  methods: {
    editOrderRemark (row) {
      this.orderOremarkForm = {
        trade_sn: row.trade_sn,
        shop_remake: "",
      };
      this.orderRemarkDialog.display({
        title: "备注",
      });
    },
    async submitRemark () {
      console.log(this.orderOremarkForm);
      try {
        if (!this.orderOremarkForm.shop_remake) {
          this.$message.error('请输入备注信息')
          return false
        }
        const res = await API_order.updateShopRemake(this.orderOremarkForm);
        this.$message.success(res)
        this.GET_OrderList()
        this.orderRemarkDialog.visible = false;
      } catch (error) {

      }
    },
    orderListSelectionChange (list) {
      this.tempList = list;
    },
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= 3 && columnIndex <= 10) {
        return {
          rowspan: 1,
          colspan: 1
        };
      } else {
        return row.span;
      }
    },
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then(res => {
        this.logisticsData = res;
      });
    },
    deliverDialog (sn, ship_no) {
      this.sku_list = [];
      this.sn = sn;
      this.ship_no = ship_no;

      this.orderPublishDialog.display();

      API_order.getOrderItemsList(sn).then(response => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach(item => {
            if (
              !item.delivery_list ||
              !item.delivery_list.length ||
              !item.delivery_list[0].delivery_no
            ) {
              item.delivery_list = [{}];
              this.sku_list.push(item);
            }
          });
          this.rawData = JSON.stringify(this.sku_list)
        }
      });
      API_order.getOrderDetail(sn).then(response => {
        // 订单信息
        if (
          response.order_status === "CANCELLED" ||
          response.service_status === "APPLY"
        ) {
          this.$message.error("该订单已取消，不需要发货");
        }
        this.orderDetail = response;
      });

    },
    openLogisticsDialog (sn) {
      this.sku_list = [];
      this.sn = sn;
      this.logisticsDialog.display();
      API_order.getLogisticsList(this.sn).then(res => {
        this.sku_list = res
      });
    },
    editLogistics () {
      let delivery_dolist = [];
      let params = JSON.stringify(this.sku_list[0].delivery_list)
      API_order.editLogistics(this.sku_list).then(res => {
        this.$message.success("保存成功");
        this.logisticsDialog.dismiss()
        this.GET_OrderList()
      });
    },
    openRemarkDialog (row) {
      this.remarkDialog.display();
      this.remarkForm.sn = row.sn; //订单编号
      this.remarkForm.platform = 2; //平台编号（0：供应商 1：大平台 2：企业端）
      this.remarkForm.remark = //订单备注
        row.order_remark === null
          ? row.order_remark
          : row.order_remark.replaceAll("/n", "\n");
      this.remarkForm.member_name = row.member_name;
    },
    editRemark () {
      if (this.remarkForm.remark === "" || this.remarkForm.remark === null) {
        return this.$message.error("备注信息不能为空");
      }
      this.remarkForm.remark = this.remarkForm.remark.replace(/\n/g, "/n");
      API_order.editOrderRemark(this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.GET_OrderList("2");
      });
    },
    // closeRemarkDialog() {
    //     this.remarkDialog = false;
    //     this.remarkTextarea = "";
    // },
    handleSelectionChange (list) {
      this.selectList = list;
    },

    checkboxT (row, index) {
      return row.state !== 3;
    },

    submitFhing () {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        if (!item.logi_id) {
          this.$message.error("请选择物流公司");
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error("请输入物流单号");
              return false;
            }
          }
        }
      }
      this.$confirm("确认发货?", "提示", { type: "warning" }).then(() => {
        let fhInfo = {
          order_sn: this.sn,
          delivery_dolist: []
        };
        let sku_list = [];
        JSON.parse(this.rawData).forEach(elem => {
          this.selectList.forEach((item, index) => {
            item.delivery_list.forEach((item1, index1) => {
              if (JSON.stringify(elem.delivery_list) === "[{}]") {
                if (elem.goods_id === item.goods_id) {
                  fhInfo.delivery_dolist.push({
                    logi_id: item.logi_id,
                    logi_name: item.logi_name,
                    order_sn: this.sn,
                    sku_id: item.product_id,
                    goods_id: item.goods_id,
                    goods_name: item.name,
                    goods_sn: item.sku_sn,
                    ship_num: item.num,
                    delivery_no: item1.delivery_no
                  });
                }
              } else {
                sku_list.push({
                  logi_id: item.logi_id,
                  logi_name: item.logi_name,
                  order_sn: this.sn,
                  sku_id: item.product_id,
                  goods_id: item.goods_id,
                  goods_name: item.name,
                  goods_sn: item.sku_sn,
                  id: item1.id,
                  ship_num: item.num,
                  delivery_no: item1.delivery_no
                })
              }
            });
          });
        })
        if (sku_list.length !== 0 && fhInfo.delivery_dolist.length !== 0) {
          API_order.deliveryNew(fhInfo).then(() => {
            API_order.editLogistics(sku_list).then(res => {
              this.$message.success("保存成功");
              this.GET_OrderList("2");
              this.orderPublishDialog.dismiss()
            });
          });
        } else if (fhInfo.delivery_dolist.length === 0) {
          API_order.editLogistics(sku_list).then(res => {
            this.$message.success("修改成功");
            this.GET_OrderList("2");
            this.orderPublishDialog.dismiss()
          });
        } else {
          API_order.deliveryNew(fhInfo).then(res => {
            this.$message.success("发货成功");
            this.GET_OrderList("2");
            this.orderPublishDialog.dismiss()
          });
        }
      });
    },

    filterLogisticsData (row) {
      this.logisticsData.forEach(res => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    // 标签切换
    handleClick (tab) {
      this.tableData = []
      this.tableDataTwo = []
      this.advancedForm = {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: []
      }
      this.params = {
        page_no: 1,
        page_size: 20,
        order_status: "ALL",
        ...this.$route.query
      };
      if (this.activeName === "1") {
        this.params["shop_type"] = 2;
      }

      this.GET_OrderList(tab.name);
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.GET_OrderList(this.activeName);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList(this.activeName);
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      // 初始化搜索条件
      delete this.params.order_sn;
      delete this.params.buyer_name;
      delete this.params.goods_name;
      delete this.params.ship_no;
      delete this.params.ship_mobile;
      delete this.params.card_code;
      delete this.params.card_number;
      delete this.params.shop_name;
      delete this.params.shop_remake;

      this.params.booking_shipment = 0;

      this.obj = {};
      if (this.search_type) {
        this.obj[this.search_type] = this.advancedForm.keywords;
      }


      this.params = {
        ...this.params,
        ...this.advancedForm,
        ...this.obj
      };

      // delete this.params.order_status;
      delete this.params.start_time;
      delete this.params.end_time;
      delete this.params.deliverStartTime;
      delete this.params.deliverEndTime;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.keywords;
      delete this.params.order_time_range;
      this.params.page_no = 1;
      if (this.activeName === "1") {
        this.GET_OrderList("1");
      } else {
        if (this.params.end_time) {
          this.params.end_time = this.params.end_time - 86400;
        }
        this.GET_OrderList("2");
      }
    },

    /** 查看、操作订单 */
    handleOperateOrder (item) {
      let route = this.$route.path.split("/")[2];
      if (this.activeName === "1") {
        this.$router.push({
          path: `/order/${route}/detail/${item.trade_sn}?type=${this.activeName}`
        });
      } else {
        this.$router.push({
          path: `/order/${route}/detail/${item.sn}?type=${this.activeName}`
        });
      }
    },

    /** 取消订单 */
    cancelTradeHandle (row) {
      if (row.delivery && row.delivery.deliveryNo) {
        this.$message.error("存在已发货商品，无法取消订单");
        this.GET_OrderList();
        return
      }
      this.$confirm('此操作将取消订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API_order.cancelTrade({ trade_sn: row.trade_sn }).then(res => {
          if (!row.isCancelOrder) row.isCancelOrder = 1
          this.$message({
            type: 'success',
            message: '订单取消成功'
          });
          this.GET_OrderList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },

    excelSuccess ({ results }) {
      this.isError = false;
      let resultsList = [];
      results.forEach(list => {
        this.templateKey.forEach((item, index) => {
          list = JSON.parse(
            JSON.stringify(list).replace(this.templateHedaer[index], item)
          );
          // if(!list[item]){
          // 	list.errorText = this.templateHedaer[index] + ' 必填'
          // 	this.isError = true
          // }
        });
        if (list.order_sn && !list["ship_num"]) {
          list.errorText = "发货数量必填";
          this.isError = true;
        } else if (Number(list["ship_num"]) > Number(list["num"])) {
          list.errorText = "发货数量大于订单数量";
          this.isError = true;
        }
        if (list.order_sn && !list["logi_name"]) {
          list.errorText = "物流公司必填";
          this.isError = true;
        }
        if (list.order_sn && !list["delivery_no"]) {
          list.errorText = "物流单号必填";
          this.isError = true;
        }

        resultsList.push(list);
      });
      this.importData = resultsList;
    },

    submitFh () {
      this.progress = '0%'
      if (!this.importData.length) {
        this.$message.error("请导入数据");
      } else if (this.isError) {
        this.$message.error("请按校验标识检查数据重新导入");
      } else {
        let data = [];
        this.importData.forEach(item => {
          data.push({
            order_sn: item.sn,
            goods_name: item.name,
            goods_sn: item.sku_sn,
            ship_num: item.num,
            logi_name: item.logi_name,
            delivery_no: item.ship_no
          });
        });
        data = data.filter(res => {
          return res.order_sn;
        });
        API_order.importOrderRequest(Math.ceil(data.length / 100))
        for (let i = 1; i <= Math.ceil(data.length / 100); i++) {
          setTimeout(() => {  // 为解决后端并发问题添加延时器
            // 拆解数据，分批导入
            let cpData = JSON.parse(JSON.stringify(data))
            let start = (i - 1) * 100
            let end = i * 100 - 1
            let handleData = []
            if (i === Math.ceil(data.length / 100)) {
              end = data.length - 1
              handleData = cpData.splice(start, data.length - start)
            } else handleData = cpData.splice(start, 100)
            // 开始导入数据
            API_order[this.funcName]({ delivery_dolist: handleData }).then(res => {
              const { no, errorExport } = res;
              if (no !== 0) {
                const { tHeaders, filterVals } = getExportMetadata(2);
                handleDownload(
                  errorExport,
                  tHeaders,
                  filterVals,
                  "失败订单列表"
                );
              }
            });
            this.addOrderLoading = true
            this.batchImport = false;
          }, (i - 1) * 1500)
        }
        this.pollImportResult()
      }
    },

    pollImportResult (type) {
      if (this.pollTime) clearInterval(this.pollTime)
      this.pollTime = setInterval(() => {
        API_order.pollImportResult().then(res => {
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
            this.GET_OrderList();
          } else {
            this.progress = Math.ceil(((res.total - res.surplus) / res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }, 3000)
      if (type === 'init') {
        API_order.pollImportResult().then(res => {
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
          } else {
            this.progress = Math.ceil(((res.total - res.surplus) / res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }
    },
    //站点订单列表导出方法
    submitImport () {
      this.importLoading = true;
      let tHeaders = [];
      let filterVals = [];
      if (this.parentLogin === 1) {
        tHeaders = [
          "订单编号",
          "商品名称",
          "商品单价",
          "分销价",
          "销售价",
          "会员价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "运费",
          "卡号及公司名称",
          "下单时间",
          "用户账号",
          "收件人",
          "收件电话",
          "收货地址",
          '备注信息'
        ];
        filterVals = [
          "trade_sn",
          "goods_name",
          "shop_price",
          "enterprise_price",
          "shop_price",
          "original_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "shop_freight_price",//运费
          "card_no_company",
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address",
          'shop_remake'
        ];
      } else {
        tHeaders = [
          "订单编号",
          "商品名称",
          "商品单价",
          "分销价",
          "销售价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "运费",
          "卡号及公司名称",
          "下单时间",
          "用户账号",
          "收件人",
          "收件电话",
          "收货地址",
          '备注信息'
        ];
        filterVals = [
          "trade_sn",
          "goods_name",
          "shop_price",
          "enterprise_price",
          "shop_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "shop_freight_price",//运费
          "card_no_company",
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address",
          'shop_remake'
        ];
      }

      let params = { ...this.params };

      params.page_no = 0;
      params.page_size = 0;
      API_order.getOrderList(params).then(res => {
        let data = mergeSkuList(res.data, 1);
        data.forEach(item => {
          item.goods_name = item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
          item.service_status = this.MixinGetOrderStatus(item.service_status);
          item.state =
            item.state === 0
              ? "未发货"
              : item.state === 2
                ? "已发货"
                : "已收货";
          item.order_source =
            item.order_source === 0
              ? "平台"
              : item.order_source === 1
                ? "京东"
                : "自有";
          item.create_time = Foundation.unixToDate(item.create_time);
          item.deliveryNo = item.delivery && item.delivery.deliveryNo;
          item["address"] = (item.consignee_province ? item.consignee_province +
            item.consignee_city +
            item.consignee_county +
            item.consignee_town +
            item.consignee_address : "")
          item.platform_price = item.order_source === '自有' ? '---' : item.platform_price;
          item.consignee_mobile = item.consignee_mobile ? item.consignee_mobile : ''
          item.consignee_name = item.consignee_name ? item.consignee_name : ''
          item.card_no_company = item.card_no_company ? this.card_no_company_filter(item.card_no_company) : ''
        });
        //需要合并的单元格
        data.forEach((m, index) => {
          let uum = 0;
          this.parentLogin === 1 ? uum = 0 : uum = 1 //是否显示会员价
          if (m.span.rowspan > 1) {
            for (let i = 0; i < 20 - uum; i++) {
              if (i < 1 || i > 10 - uum) {
                this.merges.push(
                  { s: { r: index + 1, c: i }, e: { r: index + m.span.rowspan, c: i } }
                )
              }
            }
          }
        })
        mergesHandleDownload(data, tHeaders, filterVals, "订单列表", true, this.merges);
        this.importLoading = false;
      });
    },
    /**格式化卡号公司名称 */
    card_no_company_filter (data) {
      let format = '';
      data.forEach((item, index) => {
        if (index == data.length - 1) format += `${item.card_code}-${item.company}`
        else format += `${item.card_code}-${item.company}\n`
      })
      return format
    },
    //中石油站点订单列表导出方法
    zsysubmitImport () {
      this.importLoading = true;
      let tHeaders = [];
      let filterVals = [];
      if (this.parentLogin === 1) {
        tHeaders = [
          "序号",
          "订单编号",
          "商品名称",
          "商品单价",
          "分销价",
          "销售价",
          "会员价",
          "商品数量",
          "销售价合计",
          "订单总额",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "运费",
          "卡号及公司名称",
          "下单时间",
          "用户账号",
          "收件人",
          "收件电话",
          "收货地址",
          '备注信息'
        ];
        filterVals = [
          "index_sn",
          "trade_sn",
          "goods_name",
          "shop_price",
          "enterprise_price",
          "shop_price",
          "original_price",
          "goods_num",
          "shop_total_price",
          "order_amount",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "shop_freight_price",//运费
          "card_no_company",
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address",
          'shop_remake'
        ];
      } else {
        tHeaders = [
          "序号",
          "订单编号",
          "商品名称",
          "商品单价",

          "销售价",
          "商品数量",
          "销售价合计",
          "订单总额",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "运费",
          "卡号及公司名称",
          "下单时间",
          "用户账号",
          "收件人",
          "收件电话",
          "收货地址",
          '备注信息'
        ];
        filterVals = [
          "index_sn",
          "trade_sn",
          "goods_name",
          "shop_price",

          "shop_price",
          "goods_num",
          "shop_total_price",
          "order_amount",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "shop_freight_price",//运费
          "card_no_company",
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address",
          'shop_remake'
        ];
      }

      let params = { ...this.params };

      params.page_no = 0;
      params.page_size = 0;
      API_order.getOrderList(params).then(res => {
        let indexSn = 1, data = mergeSkuList(res.data, 1);
        console.log(data);
        data.forEach((item, index) => {
          if (item.span.colspan == 1) {
            item.index_sn = indexSn++;
          }
          item.goods_name = item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
          item.service_status = this.MixinGetOrderStatus(item.service_status);
          item.state =
            item.state === 0
              ? "未发货"
              : item.state === 2
                ? "已发货"
                : "已收货";
          item.order_source =
            item.order_source === 0
              ? "平台"
              : item.order_source === 1
                ? "京东"
                : "自有";
          item.create_time = Foundation.unixToDate(item.create_time);
          item.deliveryNo = item.delivery && item.delivery.deliveryNo;
          item.member_name = (item.mobile || '') + (item.login_account ? ' ' + item.login_account : ''),
            //订单总额
            item.order_amount = item.trade_kind == 1 ? (item.shop_goods_price + item.shop_freight_price) : 0;
          item["address"] = (item.consignee_province ? item.consignee_province +
            item.consignee_city +
            item.consignee_county +
            item.consignee_town +
            item.consignee_address : "")
          item.platform_price = item.order_source === '自有' ? '---' : item.platform_price;
          item.consignee_mobile = item.consignee_mobile ? item.consignee_mobile : ''
          item.consignee_name = item.consignee_name ? item.consignee_name : ''
          item.card_no_company = item.card_no_company ? this.card_no_company_filter(item.card_no_company) : ''
        });
        let uum = 0;
        this.parentLogin === 1 ? uum = 0 : uum = 2 //是否显示会员价 (单独登录不显示会员价和分销价)
        console.log('uuu', uum);
        //需要合并的单元格
        data.forEach((m, index) => {
          if (m.span.rowspan > 1) {
            for (let i = 0; i < 23 - uum; i++) {
              if (i < 2 || i == 23 - uum - 13 || i > 13 - uum) {
                this.merges.push(
                  { s: { r: index + 1, c: i }, e: { r: index + m.span.rowspan, c: i } }
                )
              }
            }
          }
        })
        let all_goods_num = 0
        let all_shop_total_price = 0
        let all_order_amount = 0
        data.forEach((items, index) => {
          all_goods_num = this.addPrice(items.goods_num, all_goods_num)
          all_shop_total_price = this.addPrice(items.shop_total_price, all_shop_total_price)
          if (items.span.colspan == 1) {
            all_order_amount = this.addPrice(items.order_amount, all_order_amount)
          }

        })
        let obj = {
          trade_sn: '合计',
          goods_num: all_goods_num,
          shop_total_price: all_shop_total_price,
          order_amount: all_order_amount
        }
        data.push(obj)
        mergesHandleDownload(data, tHeaders, filterVals, "订单列表", true, this.merges);
        this.importLoading = false;
      });
    },
    GET_OrderList (tab = this.activeName) {
      this.loading = true;
      // 企业平台商品，站点
      if (tab === "1") {
        delete this.params.keywords;
        delete this.params.shop_member_unit_id_two;
        delete this.params.shop_member_unit_id;
        delete this.params.booking_shipment;
        delete this.params.type;
        API_order.getOrderList(this.params).then(res => {
          if (this.activeName === '1') this.loading = false
          this.pageData = res;
          this.tableData = mergeSkuList(res.data, 1);
        });
      }
    },
    //高精度加法
    addPrice (a, b) {
      a = a.toString();
      b = b.toString();
      a.split(".")[1]
        ? a.split(".")[1].length === 1 && (a += "0")
        : (a += "00");
      b.split(".")[1]
        ? b.split(".")[1].length === 1 && (b += "0")
        : (b += "00");
      return (Number(a.replace(".", "")) + Number(b.replace(".", ""))) / 100;
    },
  }
};


function getExportMetadata (type) {
  let orderFunc, filterVals, tHeaders;
  switch (type) {
    case 1:
      orderFunc = "getSellerOrderList";
      filterVals = [
        "name",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ];
      tHeaders = [
        "商品名称以及规格",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号"
      ];
      break;
    case 2:
      filterVals = [
        "goods_name",
        "goods_sn",
        "ship_num",
        // "ship_addr",
        // "ship_name",
        // "ship_mobile",
        "order_sn",
        // "order_status_text",
        "logi_name",
        //"ship_status_text",
        "delivery_no",
        "remark"
      ];
      tHeaders = [
        "商品名称",
        "商品编号",
        "数量",
        // "详细地址",
        // "姓名",
        // "电话",
        "订单号",
        // "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "错误原因"
      ];
  }

  return {
    orderFunc,
    filterVals,
    tHeaders
  };
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1545px) {
  .restyle1545 {
    margin-top: 12px;
  }
}

.restyle1545 {
  width: 410px;
}

/deep/ {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .goods-cover-wrapper .cell {
    padding-left: 0 !important;
  }
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.import-order-dialog {
  .el-dialog {
    min-width: 1024px;
    margin-top: 10vh !important;
  }

  .el-dialog__body {
    max-height: 65vh;
    overflow-y: scroll;
  }
}

/deep/.el-loading-spinner {
  .el-icon-loading,
  .el-loading-text {
    color: white;
    font-size: 16px;
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
